<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <!-- <div class="page-desc">
	  资产分类数据汇总
	 </div>  -->
	 <el-tabs v-model="active" @tab-click="handleClick">
	   <el-tab-pane label="资产分类汇总表" :name="1"></el-tab-pane> 
	   <el-tab-pane label="资产位置汇总表" :name="2"></el-tab-pane>
	 </el-tabs>
	  <el-table
		v-if="active == 1 || active == 2"
	     :data="tableData" 
		row-key="id"
		:row-style="{height:'20px'}"
		:default-expand-all="false"
		:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
		<el-table-column
			v-if="active == 1"
		    label="资产分类">
			<template slot-scope="scope">
				<span>{{scope.row.cate_code}}</span>
				<span style="margin-left: 10px;">{{scope.row.name}}</span>
			</template>
		</el-table-column>
		<el-table-column
			v-if="active == 2"
		    label="位置名称">
			 <template slot-scope="scope">
				<span>{{scope.row.location_code}}</span>
				<span style="margin-left: 10px;">{{scope.row.name}}</span>
			 </template>
		</el-table-column>
	      <el-table-column
			width="500"
	         label="资产总数"
			>
			 <template slot-scope="scope">
				 <span  @click="showAssetList(scope.row.id,scope.row.count)" style="color: #409EFF;">{{scope.row.count}}</span>
			 </template>
	     </el-table-column>
	     <el-table-column 
	         prop="sum"
	         label="购置金额(含税)">
	     </el-table-column>
	  </el-table>
	  

	  <!-- 资产清单列表 -->
	<el-dialog title="资产" :visible.sync='showAssetListStatus' width="51%"  :close-on-click-modal="false">
	  <el-table
	  	 :data="assetTableData" 
		 style="width:100%">
	  	<template v-for="item in theadData">
	  		<el-table-column :width="item.width" :key="item.field_name" :label="item.label">
	  			<template slot-scope="scope">
	  				<div v-if="item.field_name == 'status'" >
	  					<el-tag type="success" size='mini' v-if="scope.row[item.field_name] == 1">空闲</el-tag>
	  					<el-tag  size='mini' v-if="scope.row[item.field_name] == 2">派发中</el-tag>
	  					<el-tag type="warning" size='mini' v-if="scope.row[item.field_name] == 3">退库中</el-tag>
	  					<el-tag type="danger" size='mini' v-if="scope.row[item.field_name] == 4">出借中</el-tag>
	  					<el-tag type="warning" size='mini' v-if="scope.row[item.field_name] == 5">归还中</el-tag>
	  					<el-tag size='mini' v-if="scope.row[item.field_name] == 6">变更中</el-tag>
	  					<el-tag type="danger" size='mini' v-if="scope.row[item.field_name] == 7">调拨中</el-tag>
	  					<el-tag type="danger" size='mini'  effect="dark"  v-if="scope.row[item.field_name] == 8">维修中</el-tag>
	  					<el-tag size='mini'  v-if="scope.row[item.field_name] == 9" type="warning">处置中</el-tag>
	  					<el-tag size='mini' v-if="scope.row[item.field_name] == 10">修改中</el-tag>
	  					<el-tag size='mini' type="danger" v-if="scope.row[item.field_name] == 11">已报失</el-tag>
	  					<el-tag size='mini' v-if="scope.row[item.field_name] == 12">在用</el-tag>
	  				</div>
	  				<div v-if="item.type == 4  && scope.row[item.field_name] !== '-'">
	  					<el-image style="width: 35px;height: 35px;" v-for="(imgItem,imgIndex) in scope.row[item.field_name]" :src="imgItem"></el-image>
	  				</div> 
	  				<template v-if="item.field_name !== 'status' && item.type != 4">
	  					<span v-if="item.field_name == 'cate_id'">{{scope.row.cate_name}}</span>
	  					<span v-if="item.field_name == 'location_id'">{{scope.row.location_name}}</span>
	  					<span v-if="item.field_name !== 'cate_id' && item.field_name !== 'location_id'">{{scope.row[item.field_name]?scope.row[item.field_name]:'-'}}</span>
	  				</template>
	  			</template>
	  		</el-table-column>
	  	</template>
	  </el-table>
	  <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
	  </el-dialog>
 </div>
</template>

<script>
export default {
	 inject:['reload'],
    data() {
        return {
			tableData:[],
			active:1,
			showAssetListStatus:false,
			assetTableData:[],
			theadData:[],
			currentPage: 1,
			pagesize: 10,
			total: 0,
			clickId:0,
			clickCount:0
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){   //获取企业数据
			let url =  'AssetReport/category';
			if(this.active == 2){
				url =  'AssetReport/location';
			}
		    this.$api.get(url,null,res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		handleClick(val){
			this.getData()
		},
		//展示资产
		showAssetList(id,count){
			if(count <1){
				 this.$message.error('未找到资产')
				 return false
			}
			let parmas = {}
			parmas['page'] = this.currentPage-1
			parmas['limit'] = this.pagesize
			this.clickId = id
			this.clickCount =  count
			if(this.active == 1){
				parmas['cate_id'] =  id
			}
			if(this.active == 2){
				parmas['location_id'] =  id
			}
			this.$api.post('AssetReport/showAssetList',parmas,res=>{
				this.assetTableData = res.data.list ?? []
				this.total = res.data.count
				this.theadData = res.data.thead
				this.showAssetListStatus  = true
			},fail=>{
				  this.$message.error(fail.msg)
			})			
		},
		
		getCurrPage(val) { //获取当前页
			this.currentPage = val
			this.showAssetList(this.clickId,this.clickCount)
		},
		getPageSize(val) { //获取每页条数
			this.pagesize = val	
			this.showAssetList(this.clickId,this.clickCount)
		},
    },
}
</script>
<style scoped lang="scss">
</style>